import React from 'react';
import './style.css'

function Map(props) {

  return (
    <div id="svgContainer" >
     
    </div>
    
  );
}

export default Map;
 