
import { removeDuplicates, compareByName, compileDataString } from "./helpers";
export const parseSVG = (string) =>{
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(
      string,
      "image/svg+xml",
  );
  const svgElement = svgDoc.documentElement
  return svgElement
}
export const setDimensions = (props) =>{
  const dataLayer = props.svgObj.querySelector('g[id*="data"],g[id*="Data"]')
  dataLayer.parentElement.setAttribute('karstGIS','bodyLayer')
    let dataObject = {}
         if(dataLayer){
             dataObject = getDataObject(dataLayer)
         }
  const mapScale = dataObject.obj.scale?Number(dataObject.obj.scale):40
  const viewBoxData = props.svgObj.getAttribute("viewBox").split(" ")
  const originalWidth =Number(viewBoxData[2])-Number(viewBoxData[0])
  const originalHeight = Number(viewBoxData[3])-Number(viewBoxData[1])
  props.setStateFuncs.setOriginalWidth(originalWidth)
  props.setStateFuncs.setOriginalHeight(originalHeight)
  props.setStateFuncs.setScaledWidth(originalWidth*mapScale/96)
  if(dataObject.obj.north) props.setStateFuncs.setNorth(Number(dataObject.obj.north))

}
export const setInkscapeIds = (svgObj) =>{
    const gs = svgObj.querySelectorAll('g')
    Array.from(gs).forEach(g=>g.id = g.getAttribute('inkscape:label'))
}
export const checkForMapData = (dataObj)=>{
    const scale = dataObj.obj.hasOwnProperty("scale")
    const north = dataObj.obj.hasOwnProperty("north")
    const datum = dataObj.obj.hasOwnProperty("datum")
    const units = dataObj.obj.hasOwnProperty("units")
    if (!scale){
        return "No scale"
    }
    let warnings = []
    if(!north) warnings.push("NORTH: CaveView will assume that north is set at 0°.")
    if(!datum) warnings.push("DATUM: CaveView will not be able to find map datum.")
    if(!units) warnings.push("UNITS: CaveView will not be able to set scale units.")
    return warnings
}

export const parseMapData = (props) =>{
    const {svgObj,setMapData,setMapString} = props
    const dataLayer = svgObj.querySelector('g[id*="data"],g[id*="Data"]')
    let dataObject = {}
         if(dataLayer){
             dataObject = getDataObject(dataLayer)
             setMapData(dataObject.obj)
             setMapString(dataObject.str)
         }
    return dataObject
}

 export  const getDataObject = (layer)=>{
    let dataString=""
    let dataObj = {}
    if (layer!==null){
        Array.from(layer.children).forEach(l=>{
            Array.from(l.children).forEach(child=>{
                dataString+=child.innerHTML
            })
        })
        
        
        Array.from(dataString.split(";")).forEach(data=>{
            const [tag,val] = data.split(":")
            
            Object.defineProperty(dataObj,tag.toLowerCase(),{value:val?val.replaceAll("  "," "):"N/A"})
        })
    }else{

    }
      
     
      if(dataObj.length!==0) 
      
      return {obj:dataObj,str:dataString}
      
  }
  const getListOfSurveyStations = (props) => {
    const {layers,width,height,mapData} = props

    let surveyStations = []
    let designations = []
    let datum = null;
    layers.forEach((layer,ind)=>{
  
     const surveysLayers = layer.querySelector("[id*='survey'],[id*='Survey']")
     if(surveysLayers){
      const texts = surveysLayers.querySelectorAll('text')
      texts.forEach((text,index)=>{
      const name = compileDataString(text)
      const xy = text.getAttribute("transform").replace("translate(","").replace(")","").split(" ")
      const x = Number(xy[0])
      const y = Number(xy[1])
  
      const desig = ()=>{
        let d = ""
        for (let i=0;i<name.length;i++){
          const char = name[i]
          if(/[a-zA-z]/.test(char)){
            d += char;
          }else{
            if(i>0 && /[a-zA-z]/.test(name[i-1])) break;
          }
        }
        return d
      }
   
      const obj = {
        name:name,
        layer:layer.id,
        x:x/width,
        y:y/height,
        desig:desig()
      }
   
      if(mapData.datum===name){
   
        datum=obj
      }
      surveyStations.push(obj)
      designations.push(obj.desig)
     })
     }
  
     
    })
    surveyStations.sort(compareByName)
    designations.sort()
    designations = removeDuplicates(designations)
     return ({datum:datum,stations:surveyStations,designations:designations})
   }
  
   const getPlacenameString = (text) =>{
    const tspans = Array.from(text.querySelectorAll("tspan"))
    let string = ""
  
    tspans.forEach((tspan,i)=>{
      if(i>0){
        const y2 = Number(tspan.getAttribute("y"))
        const y1 = Number(tspans[i-1].getAttribute("y"))
        if((y2-y1)!==0) string += " "
      }
      if (!tspan.innerHTML.startsWith("<tspan")) string += tspan.innerHTML
    })
    return string
   }
  
   const getListOfPlacenames = (props) =>{
    const {layers,width,height} = props
    let placenames = []
    let entrances = []
    layers.forEach(layer=>{
  
      const textlayer = layer.querySelector("[id^='text'],[id^='Text']")
  
      if(textlayer){
        const placeNamelayer = textlayer.querySelector("[id^='Placename'],[id^='placename']")
        if(placeNamelayer){
          const texts = placeNamelayer.querySelectorAll('text')
  
      Array.from(texts).forEach(text=>{
        
            const name = getPlacenameString(text)
            const xy = text.getAttribute("transform").replace("translate(","").replace(")","").split(" ")
            const x = Number(xy[0])
            const y = Number(xy[1])
            const obj = {
              name:name,
              x:x/width,
              y:y/height,
              layer:layer.id
            }
          placenames.push(obj)
          if(name.toLowerCase().includes("entrance")) entrances.push(obj)
      })
        }
      }
      
  
      
    })
    placenames.sort(compareByName)
    entrances.sort(compareByName)
    return {placenames:placenames,entrances:entrances}
   }
  
  const getListOfLeads = (props) =>{
    const {layers,width,height} = props
    let leads = []
    layers.forEach(layer=>{
      let leadLayer = null
      if(layer.querySelector("[id^='text'],[id^='Text']")) leadLayer = layer.querySelector("[id^='text'],[id^='Text']").querySelector("[id^='lead'],[id^='Lead']")
   
      if(leadLayer!==null){
        const texts = leadLayer.querySelectorAll('text')

        Array.from(texts).forEach(text=>{
          
              const name = getPlacenameString(text)
              if (name.replaceAll(" ","").startsWith("?")){
                const xy = text.getAttribute("transform").replace("translate(","").replace(")","").split(" ")
              const x = Number(xy[0])
              const y = Number(xy[1])
              const obj = {
                name:name.replace("? - ","").replace("L-",""),
                x:x/width,
                y:y/height,
                layer:layer.id
              }
             
            leads.push(obj)
              }
              
       
        })
      }
    })
    leads.sort(compareByName)
    return leads
  
  }
  export const getPoints = (props)=>{

    const {svgObj,dataObj,bodyLayer,warnings} = props
    

    const body = bodyLayer
    const viewBox = svgObj.getAttribute('viewBox').split(" ")
    const width = Number(viewBox[2])-Number(viewBox[0])
    const height = Number(viewBox[3])-Number(viewBox[1])
    
    let mainLayers = []
    Array.from(body.children).forEach(child=>{
      const id = child.id.toLowerCase()
      if(!id.startsWith("text")&&!id.startsWith("data")) mainLayers.push(child)
    })
    
    const {stations,designations,datum} = getListOfSurveyStations({layers:mainLayers,width:width,height:height,mapData:dataObj.obj})
    const {placenames,entrances} = getListOfPlacenames({layers:mainLayers,width:width,height:height})
    const leads =  getListOfLeads({layers:mainLayers,width:width,height:height})



return {warnings:warnings,stations:stations,designations:designations,datum:datum,placenames:placenames,entrances:entrances,leads:leads,svgObj:svgObj}
  }
  