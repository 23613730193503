import React from 'react';
import SubMenuButton from '../../components/Buttons/SubMenuButton/SubMenuButton'
import GridButton from '../../components/Buttons/GridButton/GridButton'
import HomeButton from '../../components/Buttons/HomeButton/HomeButton';
import CrossHairButton from '../../components/Buttons/CrossHairButton/CrossHairButton';
import '../../styles/navBar.css'

function TopControls(props) {
  return (
    <div className="navBar" id="topControls">
      <HomeButton/>
     <SubMenuButton  menu="title" />
     <GridButton size='major' handle={props.setMajGridOpa} state={props.majGridOpa}/>
     <GridButton size='minor' handle={props.setMinGridOpa} state={props.minGridOpa}/>
     <CrossHairButton crossHairs={props.crossHairs} setCrossHairs={props.setCrossHairs}/>
     <SubMenuButton menu="waypoints" />
     <SubMenuButton  menu="layers" />

    </div>
    
  );
}

export default TopControls;
 