import React from 'react';
import { startsWithAny } from '../../utils/helpers';
import { setIndex } from '../../utils/helpers';
import './style.css'
function expandMenu(e){
  const parentEl = e.target.parentElement.parentElement
  if(parentEl.className==="subMenuMenu")
      {
          parentEl.className="subMenuMenu expandedMenu"
          parentEl.children[0].children[2].className = "button expander expanded"
      }
      else
      {
          parentEl.className="subMenuMenu"
          parentEl.children[0].children[2].className = "button expander"
      }
  
}
const ExpanderIcon = ()=>{
  return(<button onClick={(e)=>expandMenu(e)} className="button expander"></button>) 
}

const LayerVisibilityIcon = (props) => {
  const {index,mainLayers,setStateEmit} = props
  const handleClick = () =>{

    const newArray = setIndex(mainLayers,index,'toggleLayer')
    setStateEmit({type:'update',target:'mainLayers',value:newArray})
  }
  return(
  <button onClick={()=>handleClick()} className={`button layerIcon ${mainLayers[index].state===1?"visible":"invisible"}`}></button>
)}
const LayerFocusIcon = (props) => {
  const {index,mainLayers,setStateEmit} = props
  const handleClick = () =>{
    const newArray = setIndex(mainLayers,index,'focusLayer')
    setStateEmit({type:'update',target:'mainLayers',value:newArray})
  }
  return(
  <button onClick={()=>handleClick()} id={`layerFocus${index}`} className={`button focusIcon`}></button>
)}
const SurveyVisibilityIcon = (props) =>{
  const {index,surveyLayers,setStateEmit} = props
  const handleClick = () =>{
    const newArray = setIndex(surveyLayers,index,'toggle')
    setStateEmit({type:'update',target:'surveyLayers',value:newArray})
  }
  return(
  <button onClick={()=>handleClick()} id={`layerSurveyVis${index}`} className={`button layerIcon ${surveyLayers[index]===1?"visible":"invisible"} Survey`}></button>
)}
const TextVisibilityIcon = (props) =>{
  const {index,textLayers,setStateEmit} = props
  const handleClick = () =>{
    const newArray = setIndex(textLayers,index,'toggle')

    setStateEmit({type:'update',target:'textLayers',value:newArray})
  }
  return(
  <button onClick={()=>handleClick()} className={`button layerIcon ${textLayers[index]===1?"visible":"invisible"} Text`}></button>
)}
const LeadsVisibilityIcon = (props) =>{
  const {index,leadLayers,setStateEmit} = props
  const handleClick = () =>{
    const newArray = setIndex(leadLayers,index,'toggle')
    setStateEmit({type:'update',target:'leadLayers',value:newArray})
  }
  return(
  <button onClick={()=>handleClick()} className={`button layerIcon ${leadLayers[index]===1?"visible":"invisible"} Leads`}></button>
)}
const CeilingVisibilityIcon = (props) =>{
  const {index,ceilingLayers,setStateEmit} = props
  const handleClick = () =>{
    const newArray = setIndex(ceilingLayers,index,'toggle')
    setStateEmit({type:'update',target:'ceilingLayers',value:newArray})
  }
  return(
  <button onClick={()=>handleClick()} className={`button layerIcon ${ceilingLayers[index]===1?"visible":"invisible"} Ceiling`}></button>
)}
const DropsVisibilityIcon = (props) =>{
  const {index,dropLayers,setStateEmit} = props
  const handleClick = () =>{
    const newArray = setIndex(dropLayers,index,'toggle')
    setStateEmit({type:'update',target:'dropLayers',value:newArray})
  }
  return(
  <button onClick={()=>handleClick()} className={`button layerIcon ${dropLayers[index]===1?"visible":"invisible"} Drops`}></button>
)}
const DetailsVisibilityIcon = (props) =>{
  const {index,detailLayers,setStateEmit} = props
  const handleClick = () =>{
    const newArray = setIndex(detailLayers,index,'toggle')
    setStateEmit({type:'update',target:'detailLayers',value:newArray})
  }
  return(
  <button onClick={()=>handleClick()} className={`button layerIcon ${detailLayers[index]===1?"visible":"invisible"} Details`}></button>
)}
const AllVisibilityIcon = (props) =>{
  const {index,setStateEmit} = props
  const handleClick = () =>{
    setStateEmit({type:'update',target:'allsublayers',value:'on-'+index.toString()})
  }
  return(
  <button onClick={()=>handleClick()} className="button layerIcon active text">ALL</button>
)}
const NoVisibilityIcon = (props) =>{
  const {index,setStateEmit} = props
  const handleClick = (e) =>{
    setStateEmit({type:'update',target:'allsublayers',value:'off-'+index.toString()})
  }
  return(
  <button onClick={(e)=>handleClick(e)} id={`layerNoVis${index}`} className="button layerIcon active text">NONE</button>
)}


function Layers(props) {
  const {mainLayers,surveyLayers,textLayers,dropLayers, ceilingLayers, detailLayers, leadLayers, setStateEmit} = props
 
      const layerElements = mainLayers.map((l,i)=>{

          if (!!l&&l.hasOwnProperty('name')&&l.name!==null&&!startsWithAny(l.name.toLowerCase(),["text","data","underlayer"],false)){
            return(<div key={i} className="subMenuMenu">
              <div key={i} id={`layerElement_${l.name}`} className="subMenuHeader" >
                      <LayerVisibilityIcon index={i} mainLayers={mainLayers} setStateEmit={setStateEmit} />
                      <LayerFocusIcon index={i} mainLayers={mainLayers} setStateEmit={setStateEmit} />
                      <ExpanderIcon />
                      <div onClick={(e)=>expandMenu(e)}> {l.name}</div>
                  </div>
                  <div className="subMenuLine">
                      <SurveyVisibilityIcon index={i} surveyLayers={surveyLayers} setStateEmit={setStateEmit}/>
                      <DetailsVisibilityIcon index={i} detailLayers={detailLayers} setStateEmit={setStateEmit}  />
                      <CeilingVisibilityIcon index={i} ceilingLayers={ceilingLayers} setStateEmit={setStateEmit}/>
                      <DropsVisibilityIcon index={i} dropLayers={dropLayers} setStateEmit={setStateEmit}/>
                      <TextVisibilityIcon index={i} textLayers={textLayers} setStateEmit={setStateEmit}/>
                      <LeadsVisibilityIcon index={i} leadLayers={leadLayers} setStateEmit={setStateEmit}/>
                      <AllVisibilityIcon index={i}  setStateEmit={setStateEmit}/>
                      <NoVisibilityIcon index={i} setStateEmit={setStateEmit}/>
                  </div>
            </div>)
          }
        })
    
  return (
    <div id="subMenu_layers" className={`subMenu closed"`}>
      {layerElements}
    </div>
    
  );
}

export default Layers;