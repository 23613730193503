import React from 'react';
import '../../../styles/buttons.css'
import './style.css'
export default function CrossHairButton(props) {

  const {crossHairs,setCrossHairs} = props
  const clickEvent = ()=>{
    setCrossHairs(!crossHairs)
  }
  return (
    <button id={`crossHairs`} className={`button icon ${crossHairs?"active":"inactive"}`} onClick={()=>clickEvent()}></button>
  );
}



