import React, {useState,useEffect} from 'react'
import './style.css'
import {Link} from "react-router-dom";
import { getAllRepos } from '../utils/indexedDB';
import { RepoList } from '../components/RepoList/RepoList';

export const MyRepos = (props) =>{
   
    const [repos,setRepos] = useState([])


    useEffect(()=>{
        getAllRepos(setRepos)
    },[])

    

    return(
        <>
         <div className="bodyContainer">
            <div className="homeHeader">
                <div>My Repos</div>
                <Link to="/addEditRepos">+</Link>
            </div>
           <RepoList repos={repos}/>
            <div className="homeFooter">
                <Link to="/mymaps">My Maps</Link>
                <Link to="/betatools">Beta Tools</Link>
                <Link to="/help">Help</Link>
            </div>
        </div>
       
        </>
       
    )
}