import './style.css'
export const CrossHairs = (props) =>{
    const showHairs = props.crossHairs
    const height = window.innerHeight
    const width=window.innerWidth
    const max = Math.max(height,width)
    const l = Math.sqrt(max**2+max**2)
    const style = {
      overflow:"hidden",
    transform:`rotate(${props.angle}deg)`}
    return(
        <div id="crossHairContainer" className={showHairs===true?"show":"hide"}>
            <svg  height={height} width={width} style={style}>
                <line x1={width/2} x2={width/2} y1={0} y2={height} stroke="rgb(0, 0, 0)"   strokeWidth="1"/>
                <line x1={0} x2={width} y1={height/2} y2={height/2} stroke="rgb(0, 0, 0)" strokeWidth="1"/>
                <circle r="3" cx={width/2} cy={height/2} fill="white" stroke="rgb(0,0,0)" strokeWidth="2"/>
            </svg>
        </div>
    )
    
}