import React, {useState,useEffect} from 'react'
import './style.css'
import { Link } from "react-router-dom";
import { SingleLineInput } from '../components/SingleLineInput/SingleLineInput';
import { usernameInfo, repoInfo,pathInfo } from '../components/ToolTip/toolTipData';
import { addRepo } from '../utils/indexedDB';

export const AddEditRepo = () =>{
    const [username,setUsername] = useState("")
    const [reponame,setReponame] = useState("")
    const [path,setPath] = useState("")
    const [ready,setReady] = useState(false)
    const [reqData,setReqData] = useState({})
    
    useEffect(()=>{
        if(username!==""&&reponame!=="") setReady(true)
        else setReady(false)
        const key = Math.round(Math.random()*10000)
        const data = {index:key,username:username,repo:reponame,path:path?path:""}
        setReqData(data)
    },[username,reponame,path])
    
    return(
        <div className="bodyContainer">
            <div className="addEditHeader">
            <div>Add Repo</div>
                <Link to="/myrepos">Cancel</Link>
            </div>
            <SingleLineInput title="Owner Username" toolTipInfo={usernameInfo} setState={setUsername} placeholder="(REQUIRED)"/>
            <SingleLineInput title="Repo Name" toolTipInfo={repoInfo} setState={setReponame} placeholder="(REQUIRED)"/>
            <SingleLineInput title="Path" toolTipInfo={pathInfo} setState={setPath} placeholder="optional"/>
           
            <button onClick={()=>addRepo(reqData)} className={`addEdit ${ready?"":"disabled"}`}>Store Repo</button>
        </div> 
    )
   
}