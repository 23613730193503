import React, {useEffect,useState} from 'react'
import { getPoints, parseSVG } from '../../utils/svgParsingFunctions';
import { launchErrorModal } from '../../components/Modals/ErrorModal';
import { setLayerStates } from '../../utils/layerFuncs';
import TopControls from '../../molecules/TopControls/TopControls'
import TitleBar from '../../molecules/TitleBar/TitleBar'
import Map from '../../molecules/Map/Map'
import ScaleBar from '../../molecules/ScaleBar/ScaleBar'
import MoveControls from '../../molecules/MoveControls/MoveControls'
import TitleBlock from '../../molecules/SubMenus/TitleBlock';
import Layers from '../../molecules/SubMenus/Layers'
import { setIndex } from '../../utils/helpers';
import { Waypoints } from '../../molecules/SubMenus/Waypoints';
import MajorGrid from '../../components/Grids/MajorGrid'
import MinorGrid from '../../components/Grids/MinorGrid'
import { updateLayerVisibility } from '../../utils/layerFuncs';
import { moveTo } from '../../utils/moveAndZoom';
import { setDimensions } from '../../utils/svgParsingFunctions';
import {NorthArrow} from '../../molecules/NorthArrow/NorthArrow'
import { CrossHairs } from '../../components/CrossHairs/CrossHairs';
import { ToastContainer } from '../../components/Toast/ToastContainer';
import { makeToast } from '../../components/Toast/toastFunc';
export const MapViewer = (props) =>{
    let hasLoaded = false;
    const [mapData,setMapData] = useState({surveyors:""})
    const [mapString,setMapString] = useState("")
    const [mainLayers,setMainLayers] = useState([])
    const [surveyLayers,setSurveyLayers] =useState([])
    const [textLayers,setTextLayers] =useState([])
    const [ceilingLayers,setCeilingLayers] =useState([])
    const [dropLayers,setDropLayers] =useState([])
    const [detailLayers,setDetailLayers] =useState([])
    const [leadLayers,setLeadLayers] =useState([])
    const [bodyLayer,setBodyLayer] = useState(null)
    const [scale,setScale] = useState(40)
    const [originalScale,setOriginalScale] = useState(null)
    const [originalWidth,setOriginalWidth] = useState(0)
    const [originalHeight,setOriginalHeight] = useState(0)
    const [scaledWidth,setScaledWidth] = useState(0)
    const [north,setNorth] = useState(0)
    const [rotate,setRotate] = useState(0)
    const [orient,setOrient] = useState(false)
    const [angle,setAngle] = useState(0)
    const [float,setFloat] = useState(false)
    const [compass,setCompass] = useState(0)
    const [datum,setDatum] = useState(null)
    const [designations,setDesignations] = useState([])
    const [entrances,setEntrances] = useState([])
    const [stations, setStations] = useState([])
    const [placenames,setPlacenames] = useState([])
    const [leads,setLeads] = useState([])
    const [majGridOpa,setMajGridOpa] = useState(true)
    const [minGridOpa,setMinGridOpa] = useState(true)
    const [crossHairs,setCrossHairs] = useState(true)
    const [style,setStyle] = useState({opacity:"0"})
    const [orientPermission,setOrientPermission] = useState(false)

    const setStateFuncs = {setMainLayers:setMainLayers,
    setDropLayers:setDropLayers,
    setBodyLayer:setBodyLayer,
    setCeilingLayers:setCeilingLayers,
    setMapString:setMapString,
    setMapData:setMapData,
    setSurveyLayers:setSurveyLayers,
    setTextLayers:setTextLayers,
    setDetailLayers:setDetailLayers,
    setLeadLayers:setLeadLayers,
    setOriginalWidth:setOriginalWidth,
    setOriginalHeight:setOriginalHeight,
    setScaledWidth:setScaledWidth,
    setNorth:setNorth}

    const updateSvgVisibility = (target)=> {
        const props = {
            target:target,
            mainLayers:mainLayers,
            surveyLayers:surveyLayers,
            leadLayers:leadLayers,
            textLayers:textLayers,
            ceilingLayers:ceilingLayers,
            dropLayers:dropLayers,
            detailLayers:detailLayers
        }
        updateLayerVisibility(props)
    }

    useEffect(()=>{
        if (mapData.length>0 
            && mapString!=="" 
            && mainLayers.length!==0 
            && surveyLayers.length!==0
            && textLayers.length!==0
            && ceilingLayers.length!==0
            && dropLayers.length!==0
            && detailLayers.length!==0
            && leadLayers.length!==0
            && bodyLayer.length!==0){
            setScale(Number(mapData.scale))
            if (originalScale===null) setOriginalScale(Number(mapData.scale))
        }
    },[mapData,mapString])


    useEffect(
       ()=>{
       
           
        
        
        if(hasLoaded!==true){
            hasLoaded = true
            let warns = []
            const {mapObj} = props
            if (mapObj.length===0){
                launchErrorModal("Error","Map object is not defined.  Relaunch map from My Maps!","/")
            }else{
                
                const svgObj = parseSVG(mapObj.mapdata)
                const props = {
                    svgObj:svgObj,
                    setStateFuncs:setStateFuncs
                }
                setDimensions(props)
                const res = setLayerStates(props)
                const points = getPoints(res)
                setDatum(points.datum)
                setStations(points.stations)
                setDesignations(points.designations)
                setEntrances(points.entrances)
                setLeads(points.leads)
                setPlacenames(points.placenames)
                document.querySelector('#svgContainer').appendChild(svgObj)
               
                setTimeout(()=>{
                    moveTo({x:points.datum.x,y:points.datum.y})
                    setStyle({opacity:"1"})
                },100)
                
                setTimeout(()=>document.querySelector("#svgContainer").style.opacity="1",200)
                
                
            }
            
        }
       
       } 
    ,[])
    const [stateEmit,setStateEmit] = useState({})
    useEffect(()=>{
        if(stateEmit.hasOwnProperty('type')&&stateEmit.hasOwnProperty('target')&&stateEmit.hasOwnProperty('value')){
            if(stateEmit.type==='update'){
                if(stateEmit.target==='mainLayers') setMainLayers(stateEmit.value)
                else if(stateEmit.target==='surveyLayers') setSurveyLayers(stateEmit.value)
                else if(stateEmit.target==='textLayers') setTextLayers(stateEmit.value)
                else if(stateEmit.target==='ceilingLayers') setCeilingLayers(stateEmit.value)
                else if(stateEmit.target==='allsublayers'){
                    const target = Number(stateEmit.value.split("-")[1])
                    const value = stateEmit.value.split("-")[0]==="on"?1:0
                    setSurveyLayers(setIndex(surveyLayers,target,value))
                    setDetailLayers(setIndex(detailLayers,target,value))
                    setLeadLayers(setIndex(leadLayers,target,value))
                    setCeilingLayers(setIndex(ceilingLayers,target,value))
                    setDropLayers(setIndex(dropLayers,target,value))
                    setTextLayers(setIndex(textLayers,target,value))
                }
                updateSvgVisibility(stateEmit.target)
            }
        }
    },[stateEmit])

  

    useEffect(()=>{
        let newAngle 
        if (orient===true) newAngle=compass
        else if (float===true) newAngle=rotate
        else newAngle=north
      
        setAngle(newAngle)
        
    },[rotate,compass])

  

    return(
        <>
        <div style={style}>
           <TopControls setMajGridOpa={setMajGridOpa}
            majGridOpa={majGridOpa}
            setMinGridOpa={setMinGridOpa}
            minGridOpa={minGridOpa}
            setCrossHairs={setCrossHairs}
            crossHairs={crossHairs}/>
            <TitleBar mapData={mapData}/>
            <Map angle={angle}/>
            <ScaleBar scale={scale} mapData={mapData}/>
            <MoveControls setFloat={setFloat}  setRotate={setRotate} north={north} rotate={rotate} orient={orient} originalScale={originalScale} originalHeight={originalHeight} originalWidth={originalWidth} scaledWidth={scaledWidth} res={96} scale={scale} setScale={setScale}/>
            <TitleBlock data={mapData} dataStr={mapString}/>
            <Layers mainLayers={mainLayers} surveyLayers={surveyLayers} dropLayers={dropLayers} detailLayers={detailLayers} ceilingLayers={ceilingLayers} leadLayers={leadLayers} textLayers={textLayers} setStateEmit={setStateEmit}/>
            <Waypoints entrances={entrances} placenames={placenames} designations={designations} leads={leads} surveys={stations} setStateEmit={setStateEmit}/>
            <MajorGrid opacity={majGridOpa}/>
            <MinorGrid opacity={minGridOpa}/> 
            <NorthArrow setCompass={setCompass} setRotate={setRotate} setOrientPermission={setOrientPermission} orientPermission={orientPermission} angle={angle} float={float} north={north} orient={orient} setOrient={setOrient} rotate={rotate} />
            <CrossHairs angle={angle} crossHairs={crossHairs}/>
            <ToastContainer/>
        </div>
        
        
        </>
    )
}