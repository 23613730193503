import {useState,useEffect} from 'react'
import './SplashScreen.css'
import whiteLogo from '../../logoWhite.svg'
import loading from '../../assets/graphics/splashload.svg'
import {isSafari, isFirefox,isMobile} from 'react-device-detect'
export const SplashScreen = ({copy,version}) =>{
    const [deferredPrompt,setDeferredPrompt] = useState(null)
    const [splashOpa,setSplashOpa] = useState("1")
    const [loadingSize,setLoadingSize] = useState("0px")
    const [buttonOpa,setButtonOpa]= useState("0")
    const [buttonVis,setButtonVis] = useState("hidden")
    const [buttonDis,setButtonDis] = useState("none")
    const [resultClass,setResultClass] = useState("subtitle hide")
    const [resultText,setResultText] = ""
    const buttonStyle = {
        opacity:buttonOpa,
        visibility:buttonVis,
        display:buttonDis
    }
    const loadingStyle = {
        width: loadingSize,
        height:loadingSize
    }
    const splashStyle = {
        opacity: splashOpa
    }
    const showInstallPromotion = () =>{
        setTimeout(()=>{
            setLoadingSize("0px")
        },2100)
        setTimeout(()=>{
            setButtonDis("flex")
            setButtonVis("unset")
            setButtonOpa("1")
        },2600)
    }
    const hasInstalled = localStorage.getItem("KarstGisHasInstalled")

    useEffect(()=>{
        setTimeout(()=>{
            setLoadingSize("60px")
        },600)
        if (hasInstalled||isSafari||isFirefox){
            setTimeout(()=>{
                setLoadingSize("0px")
            },2100)
            setTimeout(()=>{
                window.location.pathname="/mymaps"
            },2600)
        }else{
            window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            showInstallPromotion();
            console.log(`'beforeinstallprompt' event was fired.`);
          })
          
        }
        window.addEventListener('appinstalled', () => {
            
            // Clear the deferredPrompt so it can be garbage collected
            setDeferredPrompt(null)
            // Optionally, send analytics event to indicate successful install
            localStorage.setItem("KarstGisHasInstalled",true)
            window.location.pathname="/mymaps"
          });
    },[])
    const handleNoThanks = ()=>{
        localStorage.setItem("KarstGisHasInstalled",true)
        window.location.pathname="/mymaps"
    }
    const handleInstall = async ()=>{
        
           deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        setDeferredPrompt(null)
        localStorage.setItem("KarstGisHasInstalled",true)
        if(outcome==="accepted"){
            setButtonVis("hidden")
            setButtonDis("none")
            if(isMobile){
                setResultClass("subtitle show")
                setResultText("Awesome!  You can open from your home screen!")
            }
            else
            {
            window.location.pathname="/mymaps"
            }
            
        }else{
            window.location.pathname="/mymaps"
        } 
        
        
    }
    return (
        <div id="splashScreen" >
            <div id="title" style={splashStyle}>
                <img src={whiteLogo} alt='whiteLogo'/>
                <div className='title'>KarstGIS</div>
                <div className='subtitle'>Software for Karst Mapping</div>
                <div className='version'>
                    {version}
                </div>
                <div className='copy'>
                    {copy}
                </div>
                <div className="installButtons">
                    <img src={loading} id="statusImage" style={loadingStyle} className="loading" alt="Status icon"/>
                    <div className="buttons" style={buttonStyle}>
                            <div className='copy'>
                            Enjoy a better experience by installing:
                            </div>
                            <button className="installButton" onClick={()=>handleInstall()}>Install</button>
                            <button className="installButton" onClick={()=>handleNoThanks()} >No thanks / Already Installed</button>
                    </div>
                </div>
                <div className={resultClass}>{resultText}</div>
            </div>
        </div>
    )
}