export const getAll = (setMaps) =>{
    let db;
    const openReq = indexedDB.open('karstgis')
    openReq.onupgradeneeded = (e)=>{
       db = e.target.result;
       db.createObjectStore('karstgismaps',{keyPath:"index"})
       db.createObjectStore('karstgisrepos',{keyPath:"index"})
   }
   openReq.onsuccess = function (e) {
   
    db = e.target.result;
   
    const tx = db.transaction(["karstgismaps"], "readwrite");

    const store = tx.objectStore('karstgismaps');

    const request = store.getAll()
   
    request.onsuccess = (e) =>{ 

        const myr = request.result
        setMaps(myr)
    }
};

}

export const getAllRepos = (setRepos) =>{
    let db;
    const openReq = indexedDB.open('karstgisRepos')
    openReq.onupgradeneeded = (e)=>{
       db = e.target.result;
       db.createObjectStore('karstgisrepos',{keyPath:"index"})
   }
   openReq.onsuccess = function (e) {
   
    db = e.target.result;
   
    const tx = db.transaction(["karstgisrepos"], "readwrite");

    const store = tx.objectStore('karstgisrepos');

    const request = store.getAll()
   
    request.onsuccess = (e) =>{ 

        const myr = request.result
        setRepos(myr)
    }
};

}

export const addRepo = (dbEntry) =>{
    let db;
    const openReq = indexedDB.open('karstgisRepos')
    
    openReq.onupgradeneeded = (e)=>{
        db = e.target.result;
        db.createObjectStore('karstgisrepos',{keyPath:"index"})
    }
    openReq.onsuccess = e =>{
        db = e.target.result;
        const tx = db.transaction(["karstgisrepos"],"readwrite")
        const store = tx.objectStore("karstgisrepos")
        store.add(dbEntry)
        window.location.pathname = "/myrepos"
   
    }
    openReq.onerror = e=>{
       
   
    }
    return {dbEntry:dbEntry}
}

export const addMap = (dbEntry) =>{
    let db;
    const openReq = indexedDB.open('karstgis')
    
    openReq.onupgradeneeded = (e)=>{
        db = e.target.result;
        db.createObjectStore('karstgismaps',{keyPath:"index"})
    }
    openReq.onsuccess = e =>{
        db = e.target.result;
        const tx = db.transaction(["karstgismaps"],"readwrite")
        const store = tx.objectStore("karstgismaps")
        store.add(dbEntry)
       
   
    }
    openReq.onerror = e=>{
       
   
    }
    return {dbEntry:dbEntry}
}

export const deleteMap = (key) =>{
let db;
const openReq = indexedDB.open('karstgis')
openReq.onsuccess = (e) =>{
    db=e.target.result
    const tx = db.transaction(["karstgismaps"],"readwrite")
    const delReq = tx.objectStore("karstgismaps").delete(key)
    tx.oncomplete = ()=> document.querySelector(`#mapEntry${key}`).remove()
}
}

export const deleteRepo = (key) =>{
    let db;
    const openReq = indexedDB.open('karstgisRepos')
    openReq.onsuccess = (e) =>{
        db=e.target.result
        const tx = db.transaction(["karstgisrepos"],"readwrite")
        const delReq = tx.objectStore("karstgisrepos").delete(key)
        tx.oncomplete = ()=> document.querySelector(`#repoEntry${key}`).remove()
    }
    }


export const getMap = (key) =>{
    let db;
    const openReq = indexedDB.open('karstgis')
    openReq.onupgradeneeded = (e)=>{
       db = e.target.result;
       db.createObjectStore('karstgismaps',{keyPath:"index"})
   }
   openReq.onsuccess = function (e) {
   
    db = e.target.result;
   
    const tx = db.transaction(["karstgismaps"], "readwrite");

    const store = tx.objectStore('karstgismaps');

    const request = store.get(key)
   
    request.onsuccess = (e) =>{ 

        const myr = request.result
        return myr
    }
}
}