import ReactDOM from 'react-dom/client'
import './ToastContainer.css'
export const makeToast = (props) =>{
    const {type,message} = props
    const Toast = ()=> {return(
       
            <div className={`toast ${type}`}>
                <div></div>
                <div>{message}</div>
                <div onClick={(e)=>{e.target.parentElement.remove()}}>X</div>
            </div>
        
    )}
    const toastContainer = document.querySelector("div#toastContainer")
    const spot = ReactDOM.createRoot(toastContainer);
    spot.render(<Toast/>)
}