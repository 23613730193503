import React, {useEffect,useRef,useState} from 'react';
import ZoomButton from '../../components/Buttons/ZoomButton/ZoomButton';
import RotateButton from '../../components/Buttons/RotateButton/RotateButton';
import FixToNorthButton from '../../components/Buttons/RotateButton/FixToNorthButton';
import '../../styles/navBar.css'
import { moveTo } from '../../utils/moveAndZoom';
import { makeToast } from '../../components/Toast/toastFunc';
function MoveControls(props) {
  const {scaledWidth, res, scale,setScale,originalScale,originalWidth,setRotate, north, originalHeight,setFloat, rotate, orient, setOrient} = props
  let holdScale = null
  const intervalRef = useRef(null)
  let counter = 0
  useEffect(()=>{return ()=>stopCounter()},[])


  const startCounter = (dir) => {
    if(holdScale===null) holdScale = (scale)
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
    if(counter>2)handleClick(dir,"hold")
    counter+=1
    }, 250);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      counter=0
    }
  };
  const factor = (dir)=>{
       
    if (dir==="in" || dir==="out") {
        if (scale===1) return(dir==="in"?0:1)
          else if (scale>1 && scale <50 ) return(dir==="in"?-1:1)
            else if (scale===50 ) return(dir==="in"?-1:5)
                  else if (scale>50 && scale <200 ) return(dir==="in"?-5:5)
                    else if (scale === 200 ) return(dir==="in"?-5:50)
                      else if (scale>200 ) return(dir==="in"?-50:50)
    }
   
    }
 
    const setMapScale = (newScale) =>{
      setScale(newScale)
      if(originalScale!==null && originalHeight!==null && originalWidth!==null){
        const svg = document.querySelector("#svgContainer>svg")
        if (svg){
          const {width,height,left,top} = svg.getBoundingClientRect()
          const contWidth = svg.parentElement.getBoundingClientRect().width
          const contHeight = svg.parentElement.getBoundingClientRect().height
          const x = (contWidth/2-left)/width
          const y = (contHeight/2-top+88)/height
         
          svg.style.width = (originalWidth*originalScale/newScale).toString()+"px"
          svg.style.height = (originalHeight*originalScale/newScale).toString()+"px"
     
          moveTo({x:x,y:y})
        }
  
   }
    }
  const handleClick = (dir,behavior) => {


    let newScale
    
    if (dir==="one") newScale =  (originalScale)
      else if (dir==="fix"){
   
        const width = document.querySelector("#svgContainer").getBoundingClientRect().width
        const fixScale = scaledWidth/(width/res)
        const roundFactor = ()=>{
          if(fixScale<=20) return 1
          if(fixScale>20 && fixScale<200) return 5
          if(fixScale>=200) return 50
        }
        
        newScale = Math.ceil(fixScale/roundFactor())*roundFactor()
  
      }
    else {
      const s = behavior==="hold"?holdScale:scale
      newScale = s+factor(dir)
    }
    if(!isNaN(newScale)){
       setMapScale(newScale)
    holdScale = (newScale)
    }else{
      makeToast({type:"error",message:"Unable to set scale."})
    }
   
  }


  return (
    <div className="navBar" id="zoomControls">
      <ZoomButton handleStartCounter={()=>startCounter("in")} handleStopCounter={()=>stopCounter()} handleClick={()=>handleClick("in")} direction="in" status="active"/>
      <ZoomButton handleStartCounter={()=>startCounter("out")} handleStopCounter={()=>stopCounter()} handleClick={()=>handleClick("out")} direction="out" status="active"/>
      <ZoomButton handleStartCounter={()=>startCounter()} handleStopCounter={()=>stopCounter()} handleClick={()=>handleClick("one")} direction="one" id="oneZoom" status="active"/>
      <ZoomButton handleStartCounter={()=>startCounter()} handleStopCounter={()=>stopCounter()} handleClick={()=>handleClick("fix")} direction="fix" status="active"/>
    </div>
    
  );
}

export default MoveControls;
 