import React from "react";
import './style.css'
import { launchAreYouSureModal } from "../Modals/AreYouSureModal";
import { launchRefreshModal } from "../Modals/RefreshModal";
export const MapList = (props) =>{
    const {maps,setMap} = props
    const mapList = maps.map((map,i)=>{

    const getMap = (props) =>{
        const {index,setAction} = props
   
    let db;
    const openReq = indexedDB.open('karstgis')
    
    openReq.onsuccess = function (e) {
   
    db = e.target.result;
   
    const tx = db.transaction(["karstgismaps"], "readwrite");

    const store = tx.objectStore('karstgismaps');

    const request = store.get(index)

    request.onsuccess = (e) =>{ 

        const currentEntry = (request.result)
        setAction(currentEntry)
        
    }}
    }
       return(
        <div className="map" key={i} id={`mapEntry${map.index}`}>
            <button onClick={()=>getMap({index:map.index,setAction:setMap})} className="icon mymaps"></button>
            <div className="mapDetails">
                <div>
                    <div className="mapName">{map.title}</div>
                </div>
                <div>
                    <div className="mapNotes">Version {map.version} - {map.date_pub}</div>
                </div>
            </div>
            <button onClick={()=>launchRefreshModal({index:map.index,target:map.title})} className="refresh icon"></button>
            <button onClick={()=>launchAreYouSureModal({index:map.index,action:"delete",target:map.title})} className="trash icon"></button>
        </div>
       ) 
    })

    return(
        <div className="myMapsContainer">
         <div className="mapsList">
            {mapList}
        </div> 
    </div>
    )
}