import React, {useState,useEffect} from 'react';
import './style.css'
import { moveTo } from '../../utils/moveAndZoom';
import { setIndex } from '../../utils/helpers';

function expandMenu(e,child){
  const parentEl = e.target.parentElement.parentElement

  if(!parentEl.classList.contains("expandedMenu"))
      {
          parentEl.className="subMenuMenu expandedMenu"
          parentEl.children[0].children[child].className = "button expander expanded"
      }
      else
      {
          parentEl.className="subMenuMenu"
          parentEl.children[0].children[child].className = "button expander"
      }
  
}
const ExpanderIcon = ({child})=>{
  return(<button onClick={(e)=>expandMenu(e,child)} className="button expander"></button>) 
}

const PlacenameList = ({placenames}) =>{

  const list = placenames.map((placename,i)=>{
    const event = ()=>{
      moveTo( {x:placename.x,y:placename.y,layer:placename.layer})
      const mainLayers = Array.from(document.querySelector("g[id='data'],g[id='Data']").parentElement.children)
      let index=null
      mainLayers.forEach((lay,i)=>{
        if (lay.tagName==="g"&&lay.id===placename.layer) index=i
      })
      if (index!==null){
       const focusButton = document.querySelector(`button[id=layerFocus${index}]`)
       if (focusButton) focusButton.click()
      }
      
    }
    return(<div onClick = {event}className="subMenuLine" key={i}>
      <div className="buffer"></div>
      {placename.name}
      <div className="subMenuHeaderNote">{placename.layer.replaceAll("_"," ")}</div>
      </div>)
  })

  return(<div className="subMenuList">{list}</div>)
 }
 const EntrancesList = ({entrances}) =>{

  const list = entrances.map((entrance,i)=>{
    const event = ()=>{
   
      moveTo( {x:entrance.x,y:entrance.y,layer:entrance.layer})
      const mainLayers = Array.from(document.querySelector("g[id='data'],g[id='Data']").parentElement.children)
      let index=null
      mainLayers.forEach((lay,i)=>{
        if (lay.tagName==="g"&&lay.id===entrance.layer) index=i
      })
      if (index!==null){
       const focusButton = document.querySelector(`button[id=layerFocus${index}]`)
       if (focusButton) focusButton.click()
      }
    }
    return(<div onClick = {event}className="subMenuLine" key={i}>
      <div className="buffer"></div>
      {entrance.name}
      <div className="subMenuHeaderNote">{entrance.layer.replaceAll("_"," ")}</div>
      </div>)
  })

  return(<div className="subMenuList">{list}</div>)
 }
 const LeadsList = ({leads}) =>{

  const list = leads.map((lead,i)=>{
    const event = ()=>{
      moveTo({x:lead.x,y:lead.y,layer:lead.layer})

      const layer = document.getElementById(lead.layer)

      const textLayer = layer.querySelector('g[id^="text"],g[id^="Text"]')

      if (textLayer) {
        const leadLayer = textLayer.querySelector('g[id^="lead"],g[id^="Lead"]')

        if(leadLayer) leadLayer.style.opacity = "1"
      }
      const mainLayers = Array.from(document.querySelector("g[id='data'],g[id='Data']").parentElement.children)
      let index=null
      mainLayers.forEach((lay,i)=>{
        if (lay.tagName==="g"&&lay.id===lead.layer) index=i
      })
      if (index!==null){
       const focusButton = document.querySelector(`button[id=layerFocus${index}]`)
       if (focusButton) focusButton.click()
      }
      
    }
    
    return(<div onClick = {event}className="subMenuLine" key={i}>
      <div className="buffer"></div>
      {lead.name}
      <div className="subMenuHeaderNote">{lead.layer.replaceAll("_"," ")}</div>
      </div>)
  })

  return(<div className="subMenuList">{list}</div>)
 }

 const SurveysList = ({surveys,designations}) => {
  const list = designations.map((desig,i)=>{

      const surveyList = surveys.map((station,i)=>{
        if (station.desig===desig){
          const event = ()=>{
            moveTo({x:station.x,y:station.y,layer:station.layer})
            const mainLayers = Array.from(document.querySelector("g[id='data'],g[id='Data']").parentElement.children)
      let index=null
      mainLayers.forEach((lay,i)=>{
        if (lay.tagName==="g"&&lay.id===station.layer) index=i
      })
      if (index!==null){
       const focusButton = document.querySelector(`button[id=layerFocus${index}]`)
       const noVisButton = document.querySelector(`button[id=layerNoVis${index}]`)
       const surveyButton = document.querySelector(`button[id=layerSurveyVis${index}]`)

     
  
        setTimeout(()=>{if (focusButton) focusButton.click()},5)
          setTimeout(()=>{if (noVisButton) noVisButton.click()},10)
            setTimeout(()=>{if (surveyButton) surveyButton.click()},15)
      }
          }
          return(
            <div className="subMenuLine" onClick={event} key={i}>
                <div className="buffer"></div>
                <div className="buffer"></div>
                {station.name}
            </div>
          )
        }
      })

      return(<div className="subMenuMenu" key={i}>
        <div className="subMenuHeader">
          <div className="buffer"></div>
          <ExpanderIcon child={1}/>
          {desig}
        </div>
        {surveyList}
      </div>)

  })

  return(<div className="subMenuList">{list}</div>)
 }

 

export const Waypoints = (props) => {
   const {entrances,placenames,designations,leads,surveys,setStateEmit} = props
   
   const Search = () =>{

      const [open,setOpen] = useState(false)

      useEffect(()=>{
        const el = document.querySelector("div#subMenu_waypoints")
        el.className = el.className.replace("full"," ") + `${open?" full":""}`
      },[open])

      const toggleOpenFocusInput = (e)=>{
          setOpen(true)
          e.target.nextSibling.focus()
      }
 
      const[input,setInput] = useState("")

      const SearchResults = ({input})=>{

        const results = (arr,tag)=>  arr.map((e,i)=>{
      
          const event = ()=>{
            moveTo({x:e.x,y:e.y,layer:e.layer})
            const mainLayers = Array.from(document.querySelector("g[id='data'],g[id='Data']").parentElement.children)
      let index=null
      mainLayers.forEach((lay,i)=>{
        if (lay.tagName==="g"&&lay.id===e.layer) index=i
      })
      if (index!==null){
       const focusButton = document.querySelector(`button[id=layerFocus${index}]`)
       if (focusButton) focusButton.click()
      }
          }
         
          return(
          <div onClick = {event} className={`searchReturn ${(input!=="" && e.name.toLowerCase().startsWith(input.toLowerCase()))?"":"hideAway"}`} key={i}>
            <div className="buffer"></div>
            <div className="subMenuHeaderNote">{tag}</div>
            <div className="buffer"></div>
            {e.name}
            <div className="subMenuHeaderNote">{e.layer.replaceAll("_"," ")}</div>
          </div>
            )
        })


        return(<div id="resultsContainer">
              {results(entrances,"ENT")}
              {results(leads,"LEAD")}
              {results(placenames,"PLACE")}
              {results(surveys,"SURV")}
        </div>)
      }

      return (
        <div className={`subMenuMenu ${open?"focused":""}`} id="searchContainer">
          <div id="searchBar" className="subMenuHeader">
          <button className="searchButton" onClick={toggleOpenFocusInput}></button>
          <input className="searchInput" onChange={e=>setInput(e.target.value)} onClick={()=>setOpen(true)} placeholder={`Search through all points.`}></input>
          <button className={`closeSearchBar ${open?"display":""}`} onClick={()=>setOpen(false)}></button>
          </div>
          <div id="searchResults" className={open?"focused":""}>
            <SearchResults input={input}/>
          </div>
        </div>
        
      )
   }

   const Placenames = () => {
    return(
      <div className="subMenuMenu">
        <div id="placenames_submenu" className="subMenuHeader">
          <ExpanderIcon child={0}/>
          Placenames
          <div className="subMenuHeaderNote"> ({placenames?placenames.length:'N/A'}) </div>
        </div>
        <PlacenameList placenames={placenames} setStateEmit={setStateEmit}/>
      </div>
    )
   }
   const Entrances = () => {
    return(
      <div className="subMenuMenu">
        <div id="entrances_submenu" className="subMenuHeader">
          <ExpanderIcon child={0}/>
          Entrances
          <div className="subMenuHeaderNote">({entrances?entrances.length:'N/A'})</div>
        </div>
        <EntrancesList entrances={entrances}/>
      </div>
    )
   }
   const Leads = () => {
    return(
      <div className="subMenuMenu">
        <div id="leads_submenu" className="subMenuHeader">
          <ExpanderIcon child={0}/>
          Leads
          <div className="subMenuHeaderNote">({leads?leads.length:'N/A'})</div>
        </div>
        <LeadsList leads={leads}/>
      </div>
    )
   }
   const Surveys = () => {
    return(
      <div className="subMenuMenu">
        <div id="surveys_submenu" className="subMenuHeader">
          <ExpanderIcon child={0}/>
          Survey Stations
          <div className="subMenuHeaderNote">({surveys?surveys.length:'N/A'})</div>
        </div>
        <SurveysList surveys={surveys} designations={designations}/>
      </div>
    )
   }
  return (
    <div id="subMenu_waypoints" className={`subMenu closed"`}>
      <div className="subMenuContents">
        <Search/>
        <Entrances/>
        <Leads/>
        <Placenames/>
        <Surveys/>
      </div>
      
    </div>
    
  );
}

