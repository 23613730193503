import React, {useState,useEffect} from 'react'
import './style.css'

export const DropDownMenu = (props) =>{

    const [selectedValue,setSelectedValue] = useState(props.placeholder)
    const [menuState,setMenuState] = useState('dropdownClosed')
    const handleDDmenu = () =>{
        if (menuState==='dropdownClosed') setMenuState('dropdownOpen')
        else setMenuState('dropdownClosed')
    }
    const handleSetData = (item,label) =>{
        setMenuState('dropdownClosed')
        setSelectedValue(label)
        props.setData(item)
    }
    if (props.list){
      const list = props.list.map((item,i)=>{
        let label
        if (props.subject==="repos"){
            label=item.username+"/"+item.repo+"/"+item.path
        }
        return(
            <div key = {i} className='dropDownItem' onClick={()=>handleSetData(item,label)}>{label}</div>
        )
    })
    return(
        <div id='dropdownMenu' className={menuState}>
            <div className='dropdownSelected'>
                <div onClick={()=>handleDDmenu()}className='dropdownSelectedValue'>{selectedValue}</div>
            </div>
            {list}
        </div>
    )  
    }
    
}