import React, {useState,useEffect} from 'react'
import './style.css'
import {Link} from "react-router-dom";

export const BetaTools = (props) =>{
    const [tracking, setTracking] = useState(false)

    const [lat,setLat] = useState(0)
    const [lon,setLon] = useState(0)
    const [acc,setAcc] = useState(0)

    const success = (pos)=>{
        setLat(pos.coords.latitude)
        setLon(pos.coords.longitude)
        setAcc(pos.coords.accuracy)
    }
    const error = ()=>{
        return
    }

   useEffect(()=>{
    if(!tracking){
        if(navigator.geolocation){
            setTracking(true)
            setInterval(()=>{
                navigator.geolocation.getCurrentPosition(success,error,{enableHighAccuracy:true})
            },5000)
        }
    }
},[])



    return(
        <div className="bodyContainer">
            <div className="homeHeader">
                <div>Beta Tools</div>
            </div>
            <div className='motionOrient'>
            Lat:{lat}
            Lon: {lon}
            Acc: {acc}
            
            </div>
            <div className="homeFooter">
                <Link to="/about">About</Link>
                <Link to="/mymaps">My Maps</Link>
                <Link to="/help">Help</Link>
            </div>
        </div>
    )
}