
import ReactDOM from 'react-dom/client';
import { Octokit } from '@octokit/rest';
import { useState,useEffect } from 'react';
import loading from '../assets/graphics/loading.png'
import errorImg from '../assets/graphics/errorX.svg'
import successImg from '../assets/graphics/success.svg'
import './fetchingStyles.css'
import { getDataObject, setInkscapeIds } from './svgParsingFunctions';
import { addMap } from './indexedDB';
import * as React from 'react'




export const FetchingModal = (props) =>{
    const {data}=props
    const auth = !!data.auth
    const url = `https://api.github.com/repos/${data.username}/${data.repo}/${data.path}`
    const [imgSrc,setImgSrc] = useState(loading)
    const [imgClass,setImgClass] = useState("fetching")
    const [resultClass,setResultClass] = useState("error")
    const [resultName,setResultName] = useState("")
    const [resultDesc,setResultDesc] = useState("")
    const [resultMess,setResultMess] = useState("")
    const [showButton,setShowButton] = useState("0")

    const handleReturn = ()=>{
        if(resultClass==="success"){
         window.location.pathname = "/mymaps"
        }else{
         document.querySelector("div.fetchingModalContainer").remove()
        }
    }

    useEffect(()=>{
        
         const octokit = new Octokit({
             auth:data.auth,
             baseUrl: 'https://api.github.com',
             mediaType:{format:'raw'}
         })
         octokit.repos.getContent({
             owner: data.username,
             repo: data.repo,
             path: data.path,
             headers: {
                 "Accept": "application/vnd.github.v3.raw"
             }
           })
        .then(res=>
         {
             if(res.status===200){
                 const parser = new DOMParser();
                 const svgDoc = parser.parseFromString(
                 res.data,
                 "image/svg+xml",
                 );
                 const svgObj = svgDoc.documentElement
                 if(svgObj.hasAttribute('xmlns:inkscape')) setInkscapeIds(svgObj)
                 const dataLayer = svgDoc.documentElement.querySelector('g[id*="data"],g[id*="Data"]')
                 dataLayer.parentElement.setAttribute("karstGIS","mainLayer")
                 let dataObject = {}
                 if(dataLayer){
                     dataObject = getDataObject(dataLayer)
                 }else{
                    throw new Error("Missing data layer, unable to load map!")
                 }
                 const key = Math.round(Math.random()*10000)
                 const dbEntry = {
                     index:key,
                     mapdata: res.data,
                     date_mod: res.headers['last-modified'],
                     size:res.headers['content-length'],
                     src:'GitHub',
                     un:data.username,
                     repo:data.repo,
                     path:data.path,
                     title:dataObject.obj.title?dataObject.obj.title:"N/A",
                     date_pub:dataObject.obj.date?dataObject.obj.date:"N/A",
                     version:dataObject.obj.version?dataObject.obj.version:"N/A",
                 }     
                 return dbEntry 
             }else throw new Error(res)
             }
        )
        .then((dbEntry)=> addMap(dbEntry))
        .then((results)=>{
         
            const {dbEntry} = results
           
                setResultClass("success")
                setImgSrc(successImg)
                setImgClass("")
                setResultName("Success!")
                setResultMess(`${dbEntry.title} - Version ${dbEntry.version} - ${dbEntry.date_pub}`)
                setResultDesc("Map file found.")
                setShowButton("1")
            
        })
        .catch((err)=>{
         setShowButton("1")
         setImgSrc(errorImg)
         setImgClass("")
         setResultName(err.name)
         setResultMess(err.message)
         setResultDesc(err.status)
     })
    },[])

    const style = {opacity:showButton}

   return(
        <div className="fetchingModal">
            <div className="fetchingModalHeader">Fetching map from:</div>
            <div className="fetchingModalMessage">{url}</div>
            <div className="fetchingModalMessage">{`Authentication Included: ${auth?"Yes":"No"}`}</div>
            <img src={imgSrc} id="statusImage" className={imgClass} alt="Status icon"/>
            <div id="fetchingModalResult" className={resultClass}>
                <div className='resultName'>{resultName}</div>
                <div className='resultDescription'>{resultDesc}</div>
                <div className='resultMess'>{resultMess}</div>
                <button className={resultClass} style={style} onClick={handleReturn}>Return</button>
            </div>
        </div>
   )
}


export const fetchFromGithub = (data)=>{
    const root = document.getElementById("root")
    const conatiner = document.createElement("div")
    conatiner.className = "fetchingModalContainer"
    root.appendChild(conatiner)
    const spot = ReactDOM.createRoot(conatiner);
    spot.render(<FetchingModal data={data}/>);
    

}