import React from 'react'
import './style.css'
import Arrow from './arrow.svg'
import { makeToast } from '../../components/Toast/toastFunc'

export const NorthArrow = (props) =>{
    const {setOrient,orient,angle,setCompass, setRotate,orientPermission,setOrientPermission} = props
    const style = {
        transform:`rotate(${angle}deg)`
    }
    const getOrientationPermission = () =>{
        if ( typeof( DeviceMotionEvent ) !== "undefined" && typeof( DeviceMotionEvent.requestPermission ) === "function" ) {
      
            DeviceMotionEvent.requestPermission()
                .then( response => {
                if ( response == "granted" ) {
                    setOrientPermission(true)
                    setOrient(true)
                    makeToast({type:'success',message:'Orientation enabled.'})
                    window.addEventListener( "deviceorientation",(e,orient)=>{
                        const compassHeading = e.webkitCompassHeading || Math.abs(e.alpha-360)
                        
                        
                            setCompass(Math.trunc(compassHeading))
                        
                    } )
                }
            })
                .catch( console.error )
        } else {
            makeToast({type:"warning",message:"Unable to use compass."})
        }
    }

    const handleClick = () =>{
        if(orientPermission===true) setOrient(!orient)
            else{
        getOrientationPermission()
        }
        
    }


    return (
        <div id="northArrow">
            <div>{orient===true?"Compass":"Fixed"}</div>
            <img style={style}  onClick={()=>handleClick()}src={Arrow} alt="north arrow" className="northArrowSVG"/>
            
            <div>{angle}°</div>
        </div>
    )
}

