import React, {useState,useEffect} from 'react'
import './style.css'
import {Link,useNavigate} from "react-router-dom";
import { getAll } from '../utils/indexedDB';
import { MapList } from '../components/MapList/MapList';

export const Home = (props) =>{
    const {setMap,mapObj,copy,version} = props
    const [maps,setMaps] = useState([])
    const navigate = useNavigate()

    useEffect(()=>{
        getAll(setMaps)
    },[])

    useEffect(()=>{
      if(mapObj!==""){
        navigate("/mapViewer")
      }
    },[mapObj])

    return(
        <>
         <div className="bodyContainer">
            <div className="homeHeader">
                <div>My Maps</div>
                <Link to="/addEditMapFromGitHub">+</Link>
            </div>
           <MapList setMap={setMap} maps={maps}/>
            <div className="homeFooter">
                <Link to="/myrepos">My Repos</Link>
                <Link to="/betatools">Beta Tools</Link>
                <Link to="/help">Help</Link>
            </div>
        </div>
       
        </>
       
    )
}