import React from "react";
import './style.css'
import { launchAreYouSureModal } from "../Modals/AreYouSureModal";
export const RepoList = (props) =>{
    const {repos} = props
    const mapList = repos.map((repo,i)=>{


       return(
        <div className="repo" key={i} id={`repoEntry${repo.index}`}>
           
            <div className="repoDetails">
                <div>
                    <div className="repoName">{repo.username}/{repo.repo}/{repo.path}</div>
                </div>
            </div>
            <button onClick={()=>launchAreYouSureModal({index:repo.index,action:"delete",target:`${repo.username}/${repo.repo}/${repo.path}`,isRepo:true})} className="trash icon"></button>
        </div>
       ) 
    })

    return(
        <div className="myMapsContainer">
         <div className="mapsList">
            {mapList}
        </div> 
    </div>
    )
}